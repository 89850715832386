const Anna = [
    './imgs/anne/anne1.jpg',
    './imgs/anne/anne2.png',
    './imgs/anne/anne3.jpg',
    './imgs/anne/anne4.png',
    './imgs/anne/anne5.png',
    './imgs/anne/anne6.jpg',
    './imgs/anne/anne7.jpg',
    // new
    './imgs/anne/anne8.jpg',
    './imgs/anne/anne9.png',
    './imgs/anne/anne10.png',
    './imgs/anne/anne11.png',
    './imgs/anne/anne12.png',
    './imgs/anne/anne13.png',
    './imgs/anne/anne14.png',
    './imgs/anne/anne15.png',
    './imgs/anne/anne16.png',
    './imgs/anne/anne17.JPG',
    './imgs/anne/anne18.png',
    './imgs/anne/anne19.png',
    './imgs/anne/anne20.png',
    './imgs/anne/anne21.png',
    './imgs/anne/anne22.JPG',
    './imgs/anne/anne24.jpg',
    './imgs/anne/anne25.png',
    './imgs/anne/anne26.JPG',
    './imgs/anne/anne27.PNG',
    './imgs/anne/anne28.JPG',
    './imgs/anne/anne29.JPG',
    './imgs/anne/anne30.png',
    './imgs/anne/anne31.JPG',
    './imgs/anne/anne32.jpg',
    './imgs/anne/anne33.png',
    './imgs/anne/anne34.png',
    './imgs/anne/anne35.jpg',
    './imgs/anne/anne36.jpg',
    './imgs/anne/anne37.JPG'

]

const Jeff = [
    './imgs/jeff/jeff11.png',
    './imgs/jeff/jeff12.png',
    './imgs/jeff/jeff13.png',
    './imgs/jeff/jeff14.png',
    './imgs/jeff/jeff15.png',
    './imgs/jeff/jeff16.png',
    './imgs/jeff/jeff17.png',
    './imgs/jeff/jeff18.png',
    './imgs/jeff/jeff19.png',
    './imgs/jeff/jeff20.png',
    './imgs/jeff/jeff21.png',
    './imgs/jeff/jeff22.png',
    './imgs/jeff/jeff23.png',
    './imgs/jeff/jeff24.png',
    './imgs/jeff/jeff25.png',
    './imgs/jeff/jeff26.png',
    './imgs/jeff/jeff27.png',
]



export {Anna, Jeff}